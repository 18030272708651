import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import StaffMixin from "@/core/lib/engineer/engineer.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
export default {
  mixins: [CommonMixin, ListingMixin, StaffMixin],
  name: "engineer-list",
  data: function data() {
    return {
      exportLoading: false,
      pageModule: "engineer-listing",
      routeAPI: "engineer",
      routeName: "engineer",
      routeDetailName: "engineer.detail",
      status: "all",
      statusList: [],
      moreActions: [
      /*{
        title: "Export Staff(s)",
        action: "export_items",
        divider: false,
        icon: "mdi-database-export",
      },*/

      /*{
        title: "Export Current View",
        action: "export_current_view",
        divider: true,
        icon: "mdi-database-export",
      },*/
      {
        title: "Refresh List",
        action: "refresh_list",
        divider: true,
        icon: "mdi-refresh"
      }],
      bulkActions: [{
        title: "Mark as Active",
        icon: "mdi-check-all",
        action: "active"
      }, {
        title: "Mark as In-Active",
        icon: "mdi-check-all",
        action: "inactive"
      }]
    };
  },
  components: {
    draggable: draggable,
    PageTips: PageTips,
    PageHeaderCount: PageHeaderCount,
    Barcode: Barcode,
    TableActivity: TableActivity,
    ListingTemplate: ListingTemplate,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingSearchCriteria: ListingSearchCriteria,
    Dialog: Dialog
  },
  methods: {
    getProfileImage: function getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }

      return null;
    },
    moreAction: function moreAction(action) {
      var _this = this;

      switch (action) {
        case "import_items":
          break;

        case "export_items":
          _this.bulkStaffExport();

          break;

        case "export_current_view":
          _this.exportCurrentView("engineer");

          break;

        case "refresh_list":
          _this.getRows();

          break;
      }
    },
    updateMoreAction: function updateMoreAction(param) {
      var _this = this;

      switch (param) {
        case "active":
          _this.bulkStaffUpdate({
            engineers: _this.selectedRows,
            status: 1
          });

          break;

        case "inactive":
          _this.bulkStaffUpdate({
            engineers: _this.selectedRows,
            status: 0
          });

          break;
      }
    },
    bulkStaffUpdate: function bulkStaffUpdate(requestObject) {
      var _this = this;

      _this.$store.dispatch(PATCH, {
        url: "engineer",
        data: requestObject
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.selectedRows = [];

        _this.getRows();
      });
    },
    bulkStaffExport: function bulkStaffExport() {
      var _this = this;

      var fileName = new Date();
      _this.exportLoading = true;

      _this.$store.dispatch(DOWNLOAD, {
        url: "engineer/export"
      }).then(function (_ref) {
        var data = _ref.data;
        saveAs(data, "engineer-export-" + fileName.getTime() + ".xlsx");
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.exportLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Staff"
    }]);
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [{
      headerName: "",
      field: "id",
      sort: null,
      visible: true,
      fixed: true,
      sortable: false,
      checkbox: true,
      order: 0,
      width: "50px"
    }, {
      headerName: "Staff #",
      field: "barcode",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 1,
      width: "200px"
    }, {
      headerName: "Image",
      field: "image",
      sort: null,
      visible: true,
      fixed: false,
      image: true,
      sortable: false,
      order: 2,
      width: "200px"
    }, {
      headerName: "Staff's Info",
      field: "engineer_info",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 3,
      width: "300px"
    }, {
      headerName: "Visit Count",
      field: "tickets",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 5,
      width: "200px"
    }, {
      headerName: "Created Time",
      child: "created_at",
      field: "added_at",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 8,
      width: "170px"
    }, {
      headerName: "Last Modified Time",
      child: "modified_at",
      field: "updated_at",
      sort: null,
      visible: false,
      fixed: false,
      sortable: false,
      order: 9,
      width: "170px"
    }];

    var defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true
    });

    _this.defaultColShow = defaultColDefs.map(function (col) {
      return col.field;
    });
    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = window.localStorage.getItem(_this.pageModule) || 10;
  }
};